:root {
    --topNavH: 10vh;
    --menuFontSize: 1.2rem;
    --bgColorMain: rgba(24, 22, 23, .6);
  }
  
  nav.TopNav
  {
    min-height: var(--topNavH);
    background-color: var(--bgColorMain);
    display: flex;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100vw;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .TopNav div.FlexLeft
  {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .TopNav div.FlexRight
  {
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      color: white;
      gap: 5vw;
  }
  
  .TopNav img.Sign
  {
    width: 50%;
  }
  
  .TopNav div.Link
  {
    box-sizing: border-box;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: right;
    height: 10vh;
  }
  
  .TopNav a.Link
  {
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    margin-bottom: 2px;
    background: linear-gradient(0deg, white, white) no-repeat right bottom / 0 var(--bg-h);
    transition: background-size 150ms;
    line-height: 1;
    --bg-h: 1px;
    font-size: var(--menuFontSize);
    padding-bottom: 4px;
  }
  
  .TopNav div.Link:where(:hover, :focus-visible) > a.Link,
  .DropDown:hover a.Link:hover
  {
    background-size: 100% var(--bg-h);
    background-position-x: left;
  }
  
  /**************************************************/
  .DropdownButton
  {
    font-size: var(--menuFontSize);
    cursor: pointer;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .DropdownContent {
    display: none;
    position: absolute;
    top: 10vh;
    background-color: var(--bgColorMain);
    flex-direction: column;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  div.Link:hover > .DropDown > .DropdownContent
  {
    display: flex;
    gap: 1vh;
    padding: 3vh 2vw
  }
  